import { alertAnatomy } from '@chakra-ui/anatomy';
import {
  AlertProps,
  type ThemeConfig,
  defineStyle,
  defineStyleConfig,
  extendTheme,
} from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const extendedFAB = defineStyle({
  size: { base: 'sm', md: 'md' },
  bottom: '80px',
  right: 3,
  rounded: {
    base: 'full',
    lg: 'md',
  },
  position: {
    base: 'fixed',
    lg: 'static',
  },
  background: 'primary.500',
  color: 'neutral.0',
  _hover: { background: 'primary.800' },
  _active: { background: 'primary.800' },
  zIndex: 100,
  WebkitTapHighlightColor: 'transparent',
});

const activeButton = defineStyle({
  color: 'primary.500',
  border: '1px',
  borderColor: 'neutral.300',
  fontSize: 'sm',
  fontWeight: 'semibold',
  bg: 'primary.100',
});

const inactiveButton = defineStyle({
  border: '1px',
  borderColor: 'gray.300',
  fontSize: 'sm',
  fontWeight: 'semibold',
  '@media (hover: hover) and (pointer: fine)': {
    _hover: { bg: 'neutral.100' },
  },
});

export const buttonTheme = defineStyleConfig({
  variants: {
    extendedFAB,
    active: activeButton,
    inactive: inactiveButton,
  },
});

const tabsTheme = {
  defaultProps: {
    colorScheme: 'primary',
  },
};

const inputTheme = {
  defaultProps: {
    colorScheme: 'primary',
    focusBorderColor: 'primary.500',
  },
};

const numberInputTheme = {
  defaultProps: {
    colorScheme: 'primary',
    focusBorderColor: 'primary.500',
  },
};

const selectTheme = {
  defaultProps: {
    colorScheme: 'primary',
    focusBorderColor: 'primary.500',
  },
};

const radioTheme = {
  defaultProps: {
    colorScheme: 'primary',
  },
};

const checkboxTheme = {
  defaultProps: {
    colorScheme: 'primary',
  },
};

const textAreaTheme = {
  defaultProps: {
    colorScheme: 'primary',
    focusBorderColor: 'primary.500',
  },
};

const switchTheme = {
  defaultProps: {
    colorScheme: 'primary',
  },
};

// ref: https://github.com/chakra-ui/chakra-ui/blob/v2/packages/theme/src/components/table.ts#L136C7-L136C23
const tableTheme = {
  sizes: {
    md: {
      th: {
        px: { base: '3', md: '6' },
        py: { base: '2', md: '3' },
      },
      td: {
        px: { base: '3', md: '6' },
        py: { base: '2', md: '4' },
      },
    },
    sm: {
      td: {
        px: { base: '2', md: '4' },
        py: { base: '1', md: '2' },
      },
    },
  },
  variants: {
    simple: {
      th: {
        //  デフォルトのborder指定だとhtml2canvas(pdf)で正しく出力されないので指定し直す
        borderBottomWidth: '1px',
        borderColor: 'neutral.200',
      },
      td: {
        //  デフォルトのborder指定だとhtml2canvas(pdf)で正しく出力されないので指定し直す
        borderBottomWidth: '1px',
        borderColor: 'neutral.200',
      },
    },
  },
  baseStyle: {
    th: {
      textTransform: 'none',
    },
  },
};

const {
  definePartsStyle: defineAlertPartsStyle,
  defineMultiStyleConfig: defineAlertMultiStyleConfig,
} = createMultiStyleConfigHelpers(alertAnatomy.keys);

const alertBaseStyle = defineAlertPartsStyle((props: AlertProps) => {
  const { status } = props;

  const successBase = status === 'success' && {
    container: {
      background: 'success',
    },
  };

  const infoBase = status === 'info' && {
    container: {
      background: 'primary.500',
    },
  };

  const loadingBase = status === 'loading' && {
    container: {
      background: 'primary.500',
    },
  };

  return {
    ...successBase,
    ...infoBase,
    ...loadingBase,
  };
});
const alertTheme = defineAlertMultiStyleConfig({ baseStyle: alertBaseStyle });

const breakpoints = {
  sm: '320px',
  md: '835px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
};

const customizeTheme = {
  colors: {
    neutral: {
      0: '#FFFFFF',
      50: '#F5F5F6',
      100: '#EBECEE',
      200: '#DEE0E3',
      300: '#D1D4D8',
      400: '#AAAFB7',
      500: '#6E7681',
      600: '#6E7681',
      700: '#3A3E44',
      800: '#191C1F',
      900: '#060607',
    },
    primary: {
      50: '#E0F4F1',
      100: '#B1E7DF',
      200: '#85D6CA',
      300: '#69C7B8',
      400: '#3DB8A5',
      500: '#37A493',
      600: '#309787',
      700: '#268778',
      800: '#156F61',
      900: '#0D594E',
    },
    gray: {
      0: '#FFFFFF',
      50: '#F5F5F6',
      100: '#EBECEE',
      200: '#DEE0E3',
      300: '#D1D4D8',
      400: '#AAAFB7',
      500: '#6E7681',
      600: '#6E7681',
      700: '#3A3E44',
      800: '#191C1F',
      900: '#060607',
    },
    blue: {
      50: '#E9F4FF',
      100: '#CDE6FE',
      200: '#ABD4FC',
      300: '#74B5F6',
      400: '#3A88EE',
      500: '#175CD3',
      600: '#0F4FBD',
      700: '#083E9B',
      800: '#073481',
      900: '#032763',
    },
    red: {
      50: '#FFF5F5',
      100: '#FEE1E1',
      200: '#FDC4C4',
      300: '#FAA3A3',
      400: '#F66F6F',
      500: '#E53E3E',
      600: '#D42B2B',
      700: '#BF362C',
      800: '#93352F',
      900: '#6B322E',
    },
    yellow: {
      50: '#FBF2E7',
      100: '#F7E6CF',
      200: '#EFCC9F',
      300: '#E7B36F',
      400: '#DF993F',
      500: '#D7800F',
      600: '#C77305',
      700: '#AD6300',
      800: '#8A4F00',
      900: '#6C4008',
    },
    green: {
      50: '#EFFBFS',
      100: '#DDF3E8',
      200: '#B5E3CE',
      300: '#74CCA3',
      400: '#46BB85',
      500: '#18AA66',
      600: '#138852',
      700: '#107043',
      800: '#0C5533',
      900: '#07331F',
    },
    error: {
      25: '#E9F4FF',
      50: '#E9F4FF',
      500: '#F04438',
      900: '#c0362c',
    },
    warning: {
      25: '#E9F4FF',
      50: '#E9F4FF',
      500: '#F79009',
      900: '#c57307',
    },
    success: '#12B76A',
    transparent: 'rgba(0,0,0,0)',
  },
  semanticTokens: {
    colors: {
      status: {
        1: 'blue.500',
        2: 'red.500',
        3: 'yellow.500',
        4: 'green.500',
        5: 'neutral.500',
      },
      text: {
        primary: 'neutral.800',
        secondary: 'neutral.500',
        error: 'red.800',
        warning: 'yellow.800',
        success: 'green.800',
        link: 'primary.700',
      },
      priority: {
        high: 'red.500',
        middle: 'yellow.500',
        low: 'green.500',
      },
      state: {
        error: 'red.500',
        warning: 'yellow.500',
        success: 'green.500',
      },
      divider: 'neutral.300',
      contentBackground: 'neutral.50',
      sideNavBackground: 'neutral.200',
      headerBackground: 'neutral.200',
    },
  },
  breakpoints,
  config,
  components: {
    Button: buttonTheme,
    Tabs: tabsTheme,
    Input: inputTheme,
    NumberInput: numberInputTheme,
    Select: selectTheme,
    Textarea: textAreaTheme,
    Checkbox: checkboxTheme,
    Switch: switchTheme,
    Alert: alertTheme,
    Table: tableTheme,
    Radio: radioTheme,
  },
  styles: {
    global: {
      body: {
        backgroundColor: '#FFF',
        overflowX: 'hidden',
      },
    },
  },
};

const theme = extendTheme(
  { ...customizeTheme }
  // withDefaultColorScheme({
  //   colorScheme: 'primary'
  // })
);

export default theme;
